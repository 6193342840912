<template>
    <div>
        <el-card class="box-card">
            <div slot="header" >
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>会员管理</el-breadcrumb-item>
                    <el-breadcrumb-item>会员管理</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div>
                <el-form :model="queryCommodityForm"  ref="queryCommodityForm" class="demo-ruleForm">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <el-form-item label="登录账号" prop="account">
                                <el-input v-model="queryCommodityForm.phone" style="width: 150px" placeholder="手机号"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="14">
                            <el-button type="primary" @click="getUserList()">查询</el-button>
                        </el-col>
                        <el-col :span="4">
                            <el-button type="primary" @click="show()">添加</el-button>
                        </el-col>
                    </el-row>
                </el-form>
                <el-table
                        height="300"
                        :data="UserList"
                        border
                        style="width: 100%">
                    <el-table-column
                            prop="vipName"
                            label="登录账号"
                            width="180">
                    </el-table-column>
                    <el-table-column
                            prop="phone"
                            label="手机号"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="quanxian"
                            label="权限"
                    >
                    </el-table-column>

                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button
                                    size="mini"
                                    type="success"
                                    @click="editUser(scope.row)">修改</el-button>
                            <el-button
                                    size="mini"
                                    type="danger"
                                    @click="deleteUser(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
        <el-dialog
                title="添加vip"
                :visible.sync="dialogVisible"
                width="40%"
                @close="dialogColsed"
        >
            <el-form :model="regForm" :rules="regRules" ref="regForm">
                <el-form-item  prop="vipName">
                    <el-input v-model="regForm.vipName" placeholder="请输入姓名"></el-input>
                </el-form-item>
                <el-form-item  prop="phone">
                    <el-input v-model="regForm.phone" placeholder="请输入手机号" ></el-input>
                </el-form-item>
                <el-form-item  prop="phone">
                    <el-radio-group v-model="regForm.quanxian">
                        <el-radio  value="全部" label="全部">全部</el-radio>
                        <el-radio value="美甲" label="美甲">美甲</el-radio>
                        <el-radio value="美睫" label="美睫">美睫</el-radio>
                    </el-radio-group>
                </el-form-item>


            </el-form>
            <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="submitRegForm('regForm')">确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="修改用户"
                :visible.sync="editDdialogVisible"
                width="40%"
                @close="editDdialogVisibleColsed"
        >
            <el-form :model="editForm" :rules="regRules" ref="editForm">
                <el-form-item  prop="vipName">
                    <el-input v-model="editForm.vipName" placeholder="请输入用户名"></el-input>
                </el-form-item>

                <el-form-item  prop="phone">
                    <el-input v-model="editForm.phone" placeholder="请输入手机号" ></el-input>
                </el-form-item>
                <el-form-item  prop="quanxian">
                    <el-radio-group v-model="editForm.quanxian">
                        <el-radio  value="全部" label="全部">全部</el-radio>
                        <el-radio value="美甲" label="美甲">美甲</el-radio>
                        <el-radio value="美睫" label="美睫">美睫</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
    <el-button @click="editDdialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="submitEditForm('editForm')">确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            // 自定义验证手机号规则
            let checkMobile = (rules, value, cb) => {
                const regMobile =
                    /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
                if (regMobile.test(value)) {
                    return cb();
                }

                cb(new Error("请输入合法手机号"));
            };
            return {
                regRules:
                    {
                        vipName: [
                            { required: true, message: '请输入用户名', trigger: 'blur' },
                            { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
                        ],
                        quanxian: [
                            { required: true, message: '请选择权限', trigger: 'blur' },

                        ],
                        phone: [
                            { validator: checkMobile, trigger: "blur" },
                            {
                                required: true,
                                message: "请输入手机号码",
                                trigger: "blur",
                            },
                        ],

                    },
                queryCommodityForm: {
                    phone: ''
                },
                UserList: [],
                regForm: {
                    vipName:'',
                    quanxian:'',
                    phone:""
                },
                editForm:{
                    vipName:'',
                    quanxian:'',
                    phone: ""
                },
                fileList:[],
                dialogVisible:false,
                editDdialogVisible: false
            }
        },
        created() {
            this.getUserList()
        },
        methods: {
            // 关闭对话框清空数据
            dialogColsed() {
                this.$refs.regForm.resetFields();

            },
            editDdialogVisibleColsed() {
                this.$refs.editForm.resetFields();

            },
            submitRegForm(formName){
                this.$refs[formName].validate(valid=>{
                    if(valid){
                        if(this.regForm.quanxian == ''){
                            this.$message.error("请选择权限")
                            return
                        }

                        this.$http.post('/user/addVip',this.regForm).then(res=>{
                            if(res.data.code==200){
                                this.$message.success("添加成功")
                                this.$refs[formName].resetFields()
                                this.dialogVisible=false
                                this.getUserList();
                            }
                            else{
                                this.$message.error(res.data.data)
                            }
                        })
                    }
                    else {
                        this.$message.error('表单填写有误请检查')
                    }
                });
            },
            editUser(row){
                this.editDdialogVisible= true
                this.$http.get('user/getListVip',{params:{phone:row.phone}}).then(res => {
                    if(res.data.code === 200){
                        this.editForm = res.data.data[0]
                    }else{
                        this.$message.error('获取失败')
                    }
                })

            },
            success(response, file) {
                this.regForm.pic=response.data;
                this.$refs['regForm'].validateField('pic')
            },
            savepathExceed() {
                this.$message.error("只能上传1张");
            },
            savepathRemove(file) {

            },
            submitEditForm(formName){
                this.$refs[formName].validate(valid=>{
                    if(valid){
                        this.$http.post('/user/updateVip',this.editForm).then(res=>{
                            if(res.data.code==200){
                                this.$message.success("修改成功")
                                this.$refs[formName].resetFields()
                                this.editDdialogVisible=false
                                this.getUserList();
                            }
                            else{
                                this.$message.error(res.data.data)
                            }
                        })
                    }
                    else {
                        this.$message.error('表单填写有误请检查')
                    }
                });
            },
            getUserList(){
                this.$http.get('user/getListVip',{params:{phone:this.queryCommodityForm.phone}}).then(res => {
                    if(res.data.code === 200){
                        this.UserList = res.data.data
                    }else{
                        this.$message.error('获取列表失败')
                    }
                })
            },
            deleteUser(row){
                this.$confirm('确定删除该vip用户？, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=> {
                    this.$http.delete('/user/deleteVip/'+row.id).then(res => {
                        if(res.data.code === 200){
                            this.getUserList()
                        }
                    });
                    this.$message({
                        type: 'success',
                        message: '删除成功!',
                    });
                }).catch(()=>{
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            show(){
                this.dialogVisible= true
            },

        }
    }
</script>

<style scoped>

</style>